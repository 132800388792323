import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import 'App.css'
import { IntlProvider } from 'react-intl'
import messagesEn from 'content/translation/en.json'
import messagesDe from 'content/translation/de.json'
import messagesEs from 'content/translation/es.json'
import { TokenComponent } from 'efs_ai_theracinghub_frontend_usermanagement'
import { useKeycloak } from '@react-keycloak/web'

const Home = lazy(() => import('home/Home'))
const About = lazy(() => import('about/About'))
const Memberships = lazy(() => import('memberships/Memberships'))
const Guide = lazy(() => import('guide/Guide'))

function App() {
  const messages: Record<string, Record<string, string>> = {
    de: messagesDe,
    en: messagesEn,
    es: messagesEs,
  }

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const languageParam = searchParams.get('lang')

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', 'light')
  }, [])

  return (
    <IntlProvider
      locale={languageParam || 'en'}
      defaultLocale='en'
      messages={messages[languageParam || 'en'] || messages.de}
    >
      <div className='App'>
        <Suspense fallback={<div className='d-flex justify-content-center vh-100 align-items-center'>Loading...</div>}>
          <Routes>
            <Route path='/About' element={<About />} />
            <Route path='/Memberships' element={<Memberships />} />
            <Route path='/Guide' element={<Guide />} />
            <Route path='*' element={<Home />} />
            <Route path='/token/:token' element={<TokenComponent />} />
          </Routes>
        </Suspense>
      </div>
    </IntlProvider>
  )
}

export default App
